import React, { useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastify";
import ToastFactory from "./components/toast-factory";
import ErrorBoundary from "./error-boundary";
import ApplicationScene from "./scenes/application/index";
import NotFoundScene from "./scenes/errors/not-found";
import ForgottenPasswordScene from "./scenes/forgotten-password";
import ForgottenPasswordThree from "./scenes/forgotten-password-three";

import ForgottenPasswordTwoScene from "./scenes/forgotten-password-two";
import HomeScene from "./scenes/home";
import LandingScene from "./scenes/landing";
import LoginScene from "./scenes/login";
import LogoutScene from "./scenes/logout";
import ResetPasswordScene from "./scenes/reset-password";
import SignupScene from "./scenes/signup/index";
import SignupOldScene from "./scenes/signup/scenes/signupagent";
import UnregisteredSetupAgentOnboarding from "./scenes/signup/onboarding-setup/unregistered-onboarding/setupAgent";
import SetupAgentSceneNew from "./scenes/signup/onboarding-setup/setup-new-agent-new/setupAgent";
import ContactUsScene from "./scenes/signup/scenes/contact-us-form";
import FAQ from "./scenes/signup/scenes/faq";
import {
  FacebookLoginCallback,
  FacebookLoginConfirmationCallback,
} from "./scenes/social/facebook";
import TermsAndConditionsScene from "./scenes/terms-and-conditions";
import VerifyEmailOneScene from "./scenes/verify-email-one";
import "./setup";
import {
  calculateTimeToRefreshToken,
  isSessionActive,
  onNewSessionBegin,
} from "./utils/auth";
import { Stopwatch } from "./utils/time";

import { notify } from "./components/extras";
import { useNetworkState } from "./utils/hooks/useNetworkState";
import RefereeInformationScene from "./scenes/signup/upgrage-agent/attestation/referreInformationScene";
import RefereePolicyScene from "./scenes/signup/upgrage-agent/attestation/refereePolicyScene";
import BvnValidationScene from "./components/cbn-onboarding/bvnValidationScene";
import FaceVerificationScene from "./components/cbn-onboarding/faceVerificationScene";
import FaceVerificationSceneMobile from "./components/cbn-onboarding/faceVerificationSceneMobile";
import IdentificationNumberScene from "./scenes/signup/onboarding-setup/kyc-continuation-flow/tinScene";
import SetupKycCompletion from "./scenes/signup/onboarding-setup/kyc-continuation-flow/setUpAgent";

import NINVerificationScene from "./components/cbn-onboarding/verifyNINScene";
import BusinessCBNScene from "./components/cbn-onboarding/businessCBNScene";
import SetupAgentCacRegistration from "./scenes/signup/onboarding-setup/cac-registration-flow/setupAgent";
import FundWalletScene from "./scenes/home/scenes/dashboard/scenes/fund-wallet/fund-wallet";
import FaceVerificationSceneFmpa from "./components/cbn-onboarding/faceVerificationSceneFmpa";
import FaceVerificationSceneOnboarding from "./components/cbn-onboarding/faceVerificationSceneOnboarding";
import FaceVerificationSceneAggregator from "./scenes/home/scenes/aggregator-module/setup-new-agent-revamp/faceVerificationScene";

import FundDebitMobileScene from "./scenes/home/scenes/dashboard/scenes/fund-wallet/fund-via-debit-mobile";
export * from "./setup/api";
export * from "./setup/session-timers";

export const stopwatch = new Stopwatch();

class App extends React.Component {
  componentDidMount() {
    calculateTimeToRefreshToken();
    isSessionActive() && onNewSessionBegin();
  }
  render() {
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <Switch>
          <Route exact path="/" component={LandingScene} />
          <PrivateRoute
            exact
            path="/application"
            component={ApplicationScene}
          />
          <Route exact path="/contact-us" component={ContactUsScene} />
          <Route
            exact
            path="/forgotten-password"
            component={ForgottenPasswordScene}
          />
          <Route
            path="/forgotten-password-two"
            component={ForgottenPasswordTwoScene}
          />
          <Route
            path="/forgotten-password-three"
            component={ForgottenPasswordThree}
          />

          <Route exact path="/login" component={LoginScene} />
          <Route exact path="/logout" component={LogoutScene} />
          <Route
            path="/reset-password/:passwordResetUid"
            component={ResetPasswordScene}
          />
          <Route exact path="/signup" component={SignupScene} />
          <Route exact path="/signupOld" component={SignupOldScene} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/bvn-validation" component={BvnValidationScene} />
          <Route
            exact
            path="/face-verification"
            component={FaceVerificationScene}
          />

          <Route
            exact
            path="/kyc-continuation-flow/setupagent"
            component={SetupKycCompletion}
          />

          <Route
            exact
            path="/face-verification-mobile"
            component={FaceVerificationSceneMobile}
          />
          <Route
            exact
            path="/face-verification-fmpa"
            component={FaceVerificationSceneFmpa}
          />
          <Route
            exact
            path="/face-verification-mobile-onboarding"
            component={FaceVerificationSceneOnboarding}
          />
          <Route
            exact
            path="/nin-verification"
            component={NINVerificationScene}
          />
          <Route exact path="/business-cbn" component={BusinessCBNScene} />
          <Route
            exact
            path="/cac-registration"
            component={SetupAgentCacRegistration}
          />
          <Route
            exact
            path={`/fund-via-debit`}
            component={FundDebitMobileScene}
          />

          <Route exact path="/fund-wallet" component={FundWalletScene} />

          {process.env.REACT_APP_NAV_MENU_SHOW_KYC_UPDATE === "true" ? (
            <Route
              exact
              path="/signup/onboarding-new-agent"
              component={SetupAgentSceneNew}
            />
          ) : (
            <Route
              exact
              path="/signup/onboarding-unregistered-agent"
              component={UnregisteredSetupAgentOnboarding}
            />
          )}
          <Route
            exact
            path="/social/facebook/login-callback"
            component={FacebookLoginCallback}
          />
          <Route
            exact
            path="/social/facebook/login-callback/confirmation"
            component={FacebookLoginConfirmationCallback}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditionsScene}
          />
          <Route
            exact
            path="/verify-email-one"
            component={VerifyEmailOneScene}
          />
          <Route
            exact
            path="/upgrade/attestation"
            component={RefereeInformationScene}
          />

          <Route
            exact
            path="/referee-attestation-policy"
            component={RefereePolicyScene}
          />
          <PrivateRoute path="/" component={HomeScene} />
          <Route component={NotFoundScene} />
        </Switch>

        <ToastFactory />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <ProgressBar
          variant="danger"
          hidden={!this.props.isLoading}
          now={this.props.loadingPercentage}
          style={{
            color: process.env.REACT_APP_RED_COLOUR,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: ".5%",
            zIndex: 100,
          }}
        />
      </Router>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isOnline, connectionType, connectionSpeed } = useNetworkState();
  const determineLoginRoute = (props) => {
    return props.location.search ? (
      <Redirect to={`/login${props.location.search}`} />
    ) : (
      <Redirect to="/Login" />
    );
  };
  const checkNetworkStatus = () => {
    if (isOnline === "online") {
      notify(`You are back online`, "success", "top-center");
      setTimeout(() => {
        window.location.reload(false);
      }, 6000);
    } else if (isOnline === "offline") {
      notify(`You are offline, check your connection`, "error", "top-center");
    } else {
      return "";
    }
  };
  useEffect(() => {
    checkNetworkStatus();
  }, [isOnline]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isSessionActive() ? (
          <div>
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          </div>
        ) : (
          determineLoginRoute(props)
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    errorMessage: state.tunnel.errorMessage,
    successMessage: state.tunnel.successMessage,
    isLoading: state.tunnel.isLoading,
    loadingPercentage: state.tunnel.loadingPercentage,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
// testing
