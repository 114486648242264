import React from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { platformServiceClient } from "../../App";
import ComplaintForm from "../../complaint/complaint-form";
import ManageComplaint from "../../complaint/manage-complaint";
import IssueReportScene from "../../complaint/reports/scene";
import Header from "../../components/header";
import NavBar from "../../components/nav-bar";
import TourGuide from "../../components/tour-guide";
import { START_LOAD_USER_DATA } from "../../constants/action-types/user";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../constants/api";
import AgentSerializer from "../../serializers/resources/agent";
import ApplicationSerializer from "../../serializers/resources/application";
import UserSerializer from "../../serializers/resources/user";
import Onboarding from "../../services/api/resources/onboarding";
import Platform from "../../services/api/resources/platform";
import { setActiveTab } from "../../services/redux/actions/navigation";
import {
  dismissErrorMessage,
  showErrorMessage,
} from "../../services/redux/actions/tunnel";
import {
  endLoadUserData,
  setLoadingUserData,
} from "../../services/redux/actions/user";
import { formatPhoneNumber } from "../../utils/formatters";
import BaseScene from "../base-scene";
import AccountOpeningScene from "./scenes/account-opening";
import AccountOpeningDocumentsScene from "./scenes/account-opening/account-opening-documents";
import AgentsScene from "./scenes/aggregator-module/agents/scene";
import CommisionsSceen from "./scenes/aggregator-module/commissions/commisions";
import PerformanceSceen from "./scenes/aggregator-module/performance/performance";
import InventorySceen from "./scenes/aggregator-module/pos-management/inventory/inventory";
import MyPosRequestScreen from "./scenes/aggregator-module/pos-management/my-pos-requests/myPosRequests";
import TrackShipment from "./scenes/aggregator-module/pos-management/my-pos-requests/myRequests/trackShipment";
import RequestPosAggregatorScreen from "./scenes/aggregator-module/pos-management/my-pos-requests/requestPos/requestPos";
import CompleteDebitCardLinkScene from "./scenes/complete-debit-card-link";
import DashboardScene from "./scenes/dashboard";
import Skeleton from "./scenes/dashboard/components/skeleton";
import HistoricalTransactionsScene from "./scenes/historical-transactions";
import LinkDebitCardScene from "./scenes/link-debit-card";
import ManageDevicesScene from "./scenes/manage-devices";
import SetupAgentDisplay from "./scenes/manage-profile";
import ManageProfileSceneNew from "./scenes/manage-profile/sceneNew";
import NotificationScene from "./scenes/notification/scene";
import ReportsScene from "./scenes/reports";
import SecuritySettingsScene from "./scenes/security-settings";
import ServicesScene from "./scenes/services";
import RolesScene from "./scenes/staff-management/scenes/roles";
import UsersScene from "./scenes/staff-management/scenes/users";
import WalletJournalScene from "./scenes/wallet-journal/scene";

//import { loadUserObject } from "../../utils/auth";

import moment from "moment";
import { ReopenTicket } from "../../complaint/reopen-ticket";
import { TicketDetails } from "../../complaint/ticket-details";
import {
  PENDING_VERIFICATION,
  SUSPENDED,
} from "../../constants/bvnVerificationStatus";
import { logoutUser } from "../../utils/auth";
import ListOfUpgradeType from "../signup/upgrage-agent/listOfclassScreen";
import SetupAgentResume from "../signup/upgrage-agent/resumeUpdateScreen/setupAgent";
import SetupNewAgentClass from "../signup/upgrage-agent/upgradeAgentClass";
import SetupNewAgentClassPrestige from "../signup/upgrage-agent/upgradeAgentPresitgeCorporate";
import AccountDowngradeScene from "./scenes/account-downgrade";
import manageAgentScene from "./scenes/aggregator-module/agents/manage-agent/manageAgentScene";
import ListOfclassScreen from "./scenes/aggregator-module/agents/upgrage-agent/listOfclassScreen";
import SetupAgentClass from "./scenes/aggregator-module/agents/upgrage-agent/upgradeAgentClass/index";
import ApplicationsDetailsScene from "./scenes/aggregator-module/applications/applicantDetails";
import ApplicationsScene from "./scenes/aggregator-module/applications/application";
import UnloadBalanceSceen from "./scenes/aggregator-module/commissions/earnings/unloadBalance";
import WithdrawalDetailsSceen from "./scenes/aggregator-module/commissions/withdrawals/withdrawalDetails";
import ApprovePosScreen from "./scenes/aggregator-module/pos-management/my-pos-requests/agentsRequests/approvePos";
import SetupAgentRevamp from "./scenes/aggregator-module/setup-new-agent-revamp/index";
import SetupAgent from "./scenes/aggregator-module/setup-new-agent/index";

import BankNetwork from "./scenes/bank-network";
import ManageDevicesAuditTrailScene from "./scenes/manage-devices/manage-devices-audit-trail";
import NinInformation from "./scenes/nin-information";
import DelieveryNote from "./scenes/pos-management/delievery-note";
import PosReport from "./scenes/pos-management/pos-report/pos-report-table";
import RequestPos from "./scenes/pos-management/pos-request/request";
import RemapScene from "./scenes/pos-management/remap";
import PosManagement from "./scenes/pos-management/scene";
import TrackPosOrder from "./scenes/pos-management/track-pos-order/request-details";
import TrackPosDelivery from "./scenes/pos-management/track-pos-order/track-delivery";
import ProcessingBvnScene from "./scenes/processing-bvn";
import ConcurDelivery from "./scenes/reports/tables/concur-delivery";
import { forceAgentToVerifyKyc } from "../../components/cbn-onboarding/mockApi";
import FaceVerificationSceneAggregator from "./scenes/aggregator-module/setup-new-agent-revamp/faceVerificationScene";

class HomeScene extends BaseScene {
  isAuthRequired = true;

  constructor(props) {
    super(props);

    this.state = {
      currentAgent: null,
      currentApplication: null,
      didErrorOccurWhileFetching: null,
      disabled: null,
      hasMounted: false,
      pageSize: "",
      pageNum: "",

      mobileNavBarOpacity: 0,
      mobileNavBarRight: "100vw",
      showMobileNavBar: false,
      showModal: false,
      showNewCbnModal: true,
      showUpdateAccountModal: true,
      showCompleteOnboarding: true,
      showUpdateCompleteOnboarding: true,
    };

    this.onboarding = new Onboarding();
    this.platform = new Platform();

    this.hideNavBar = this.hideNavBar.bind(this);
    this.loadAgent = this.loadAgent.bind(this);
    this.loadApplication = this.loadApplication.bind(this);
    this.loadData = this.loadData.bind(this);
    // this.loadUser = this.loadUser.bind(this);
    this.showNavBar = this.showNavBar.bind(this);
    this.hideUpdateModal = this.hideUpdateModal.bind(this);
    this.showUpdateModal = this.showUpdateModal.bind(this);
    // this.getPosRequestReport = this.getPosRequestReport.bind(this);

    this.getBVNInformation = this.getBVNInformation.bind(this);
    this.getBVNVerificationStatus = this.getBVNVerificationStatus.bind(this);
    this.getShowModal = this.getShowModal.bind(this);
    this.getBVNGracePeriod = this.getBVNGracePeriod.bind(this);
    this.setBVNNotificationFlag = this.setBVNNotificationFlag.bind(this);

    this.isGracePeriodValid = this.isGracePeriodValid.bind(this);

    this.setEnableNavBar = this.setEnableNavBar.bind(this);

    this.isSuspendedAgent = this.isSuspendedAgent.bind(this);
  }

  getShowModal() {
    const modalShown = localStorage.getItem("modalShown");
    if (modalShown === null) {
      setTimeout(() => this.setState({ showModal: true }), 3000);

      localStorage.setItem("modalShown", "true");
    }
  }

  closeModal = () => {
    this.setState({ showUpdateAccountModal: false });
  };

  closeModalBvn = () => {
    this.setState({ showNewCbnModal: false });
  };

  componentDidMount() {
    const doHardLoadUser = false;

    const doHardLoadAgent = JSON.parse(
      process.env.REACT_APP_FETCH_AGENT_DATA_ON_DASHBOARD_LOAD
    );
    this.loadData(doHardLoadUser, doHardLoadAgent);
    // this.getPosRequestReport(this.state.pageSize, this.state.pageNum);
    //call bvn info API

    this.getBVNInformation().then((data) => {
      data
        ? this.setState({
            bvnVerificationStatus: data.bvnVerificationStatus,
            bvnGracePeriod: data.bvnGracePeriod,
            bvnNotificationFlag: true,
          })
        : console.log("something went wrong,can't get agent detail: " + data);
    });
  }

  getBVNVerificationStatus() {
    return this.state.bvnVerificationStatus;
  }

  getBVNGracePeriod() {
    return this.state.bvnGracePeriod;
  }

  setBVNNotificationFlag(flag) {
    this.setState({
      bvnNotificationFlag: flag,
    });
  }

  async getBVNInformation() {
    const { status, response } = await platformServiceClient.getCurrentAgent();
    //alert(JSON.stringify(response.bvnVerificationStatus))

    if (status === ERROR_STATUS) {
      // this.setState({
      //   didErrorOccurWhileFetching: true,
      // })
      return null;
    }
    localStorage.setItem("currentAgent", JSON.stringify(response));
    if (response.agentClass === "BASIC") {
      this.getShowModal();
    }
    return response;
  }

  async loadApplication(doHardLoad = true) {
    if (!doHardLoad) {
      return JSON.parse(localStorage.getItem("currentApplication"));
    }

    const currentApplication = JSON.parse(
      localStorage.getItem("currentApplication") || "{}"
    );
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");

    const { firstName, lastName, mobileNo, email } = currentUser;

    const createApplicationPayload = {
      applicantDetails: {
        firstName,
        surname: lastName,
        emailAddress: email,
        phoneNumber: formatPhoneNumber(mobileNo),
      },
    };

    const responseObj = await this.onboarding.getApplicationByEmailOrPhoneOrId(
      currentUser.mobileNo
    );
    const { status, response, code } = responseObj;

    if (status === SUCCESS_STATUS) {
      let newState = {};

      if (response.applicationType === "DRAFT") {
        newState.disabled = true;
        newState.shouldShowApplicationStatusModal = true;
      } else if (
        response.approvalStatus &&
        response.approvalStatus !== "APPROVED"
      ) {
        newState.disabled = true;
        newState.shouldShowApplicationStatusModal = true;
      } else if (
        response.approvalStatus &&
        response.approvalStatus === "APPROVED"
      ) {
        newState.disabled = true;
        newState.showAddBillingInformation = true;
        newState.shouldShowAddBillingInformation = true;
      }

      this.setState(newState);

      localStorage.setItem("currentApplication", JSON.stringify(response));

      this.applicationSerializer = new ApplicationSerializer(response);
    } else {
      if (code === 404) {
        const onboardingResponseObj = await this.onboarding.createApplication(
          createApplicationPayload
        );
        const { response, code, status } = onboardingResponseObj;

        if (status === ERROR_STATUS) {
          this.setState({
            disabled: true,
          });
          return null;
        }

        if (status === SUCCESS_STATUS) {
          localStorage.setItem("currentApplication", JSON.stringify(response));
          this.applicationSerializer = new ApplicationSerializer(response);
        }
      }

      if (status === ERROR_STATUS) {
        this.setState({
          disabled: true,
        });
        return null;
      }

      this.applicationSerializer = new ApplicationSerializer(
        currentApplication
      );
      this.setState({
        currentApplication: this.applicationSerializer,
        disabled: !this.applicationSerializer.isApproved,
      });
      this.props.updateLoading(false);
      return null;
    }

    let newState = {
      currentApplication: this.applicationSerializer,
      disabled: !this.applicationSerializer.isApproved,
    };

    this.setState(newState);
    this.props.updateLoading(false);
  }

  async loadAgent(doHardLoad = true) {
    if (!doHardLoad) {
      return JSON.parse(localStorage.getItem("currentAgent"));
    }

    let newState = {};

    const getAgentResponseObj = await this.platform.getCurrentAgent();
    const { status, response } = getAgentResponseObj;

    if (status === ERROR_STATUS) {
      this.setState({
        disabled: true,
      });
      return null;
    }

    if (status === SUCCESS_STATUS) {
      const currentAgent = response;
      localStorage.setItem("currentAgent", JSON.stringify(currentAgent));
      this.agentSerializer = new AgentSerializer(currentAgent);

      newState = {
        ...newState,
        currentAgent: this.agentSerializer,
        disabled: newState.disabled || this.agentSerializer.isDisabled,
      };
    }

    this.setState(newState);
    this.props.updateLoading(false);
  }

  // BUGS HERE (Awaiting Validation, Awaiting Approval) shows after refresh
  async loadData(
    doHardLoadUser = true,
    doHardLoadAgent = true,
    doHardLoadApplication = true
  ) {
    this.props.updateLoading(true, 22);

    let didErrorOccurWhileFetching = null;

    const userData = await this.loadUser(doHardLoadUser);
    didErrorOccurWhileFetching = userData === null;
    if (didErrorOccurWhileFetching) {
      this.setState({
        didErrorOccurWhileFetching,
      });
      this.props.updateLoading(false);
      return;
    }

    const user = new UserSerializer(userData);

    if (doHardLoadAgent && user.isAgent) {
      this.props.updateLoading(true, 44);
      didErrorOccurWhileFetching =
        (await this.loadAgent(doHardLoadAgent)) === null;
    }

    if (user.isApplicant) {
      this.props.updateLoading(true, 66);
      didErrorOccurWhileFetching =
        (await this.loadApplication(doHardLoadApplication)) === null;
    }

    if (!user.canAccessDashboard) {
      this.setState({
        disabled: true,
      });
      // window.location.href = '/logout?case=CANNOT_ACCESS_DASHBOARD'
    }

    this.props.updateLoading(false);
    this.setState({
      didErrorOccurWhileFetching,
      hasMounted: true,
    });
  }

  async loadUser(doHardLoad = true) {
    if (!doHardLoad) {
      return JSON.parse(localStorage.getItem("currentUser"));
    }

    const responseObj = await this.platform.getCurrentUser();
    const { status, response } = responseObj;

    if (status === ERROR_STATUS) {
      return null;
      // return JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    localStorage.setItem("currentUser", JSON.stringify(response));

    return response;
  }

  hideNavBar() {
    this.setState({
      mobileNavBarOpacity: 0,
      mobileNavBarRight: "100vw",
    });

    setTimeout(
      () =>
        this.setState({
          showMobileNavBar: false,
        }),
      300
    );
  }

  showNavBar() {
    this.setState({
      showMobileNavBar: !this.state.showMobileNavBar,
    });

    setTimeout(
      () =>
        this.setState({
          mobileNavBarOpacity: 1,
          mobileNavBarRight: 0,
        }),
      0
    );
  }

  hideUpdateModal() {
    this.setState({
      showModal: false,
    });
    localStorage.setItem("modalShown", "false");
  }

  showUpdateModal() {
    this.setState({
      showModal: false,
    });
    this.props.navigateTo("/manage-profile");
    localStorage.setItem("modalShown", "false");
  }

  onIdle = () => {
    window.location = `/logout`;
  };

  isGracePeriodValid() {
    const startDate = moment(); //24h format
    if (this.state.bvnGracePeriod) {
      const timeEnd = moment(this.state.bvnGracePeriod);
      const diff = timeEnd.diff(startDate);
      return diff > 0 || diff === 0;
    }

    return true; // indeterminate ie undefined grace period
  }

  setEnableNavBar(flag) {
    this.setState({
      disabled: flag,
    });
  }

  isSuspendedAgent() {
    return (
      this.state.bvnVerificationStatus === SUSPENDED ||
      !this.isGracePeriodValid()
    );
  }

  handleCloseModal = () => {
    this.setState({ showNewCbnModal: false });
  };

  handleCloseModal2 = () => {
    this.setState({ showCompleteOnboarding: false });
  };
  render() {
    const { didErrorOccurWhileFetching } = this.state;

    if (this.props.loadUserStatus === START_LOAD_USER_DATA) {
      const doHardLoadUser = false;
      const doHardLoadAgent = process
        ? JSON.parse(process.env.REACT_APP_FETCH_AGENT_DATA_ON_DASHBOARD_LOAD)
        : "";
      const doHardLoadApplication = true;

      this.props.setLoadingUserData();
      this.loadData(
        doHardLoadUser,
        doHardLoadAgent,
        doHardLoadApplication
      ).then((response) => {
        this.props.endLoadUserData();
      });
    }

    if (this.props.pendingUrl) {
      const { pendingUrl } = this.props;
      this.props.navigateTo(null);
      return <Redirect to={pendingUrl} />;
    }

    const EmailValidationroute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect to={`/agent/dashboard${props.location.search}`} />
          )}
        />
      );
    };

    const switchComponent =
      this.state.hasMounted && didErrorOccurWhileFetching === false ? (
        this.state.bvnVerificationStatus === PENDING_VERIFICATION &&
        this.isGracePeriodValid() === false ? (
          <Switch>
            <Route
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/`}
              render={() => (
                <ProcessingBvnScene disableNavBar={this.setEnableNavBar} />
              )}
            />
          </Switch>
        ) : (
          <Switch>
            {/* <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`} component={DashboardScene} /> */}
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/processing-bvn`}
              render={() => (
                <ProcessingBvnScene disableNavBar={this.setEnableNavBar} />
              )}
            />

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`}
              render={() => (
                <DashboardScene
                  bvnNotificationFlag={this.state.bvnNotificationFlag}
                  setBVNNotificationFlag={this.setBVNNotificationFlag}
                  bvnVerificationStatus={this.state.bvnVerificationStatus}
                  isGracePeriodValid={this.isGracePeriodValid}
                  setActiveTab={this.props.setActiveTab}
                  isSuspendedAgent={this.isSuspendedAgent}
                  bvnGracePeriod={this.state.bvnGracePeriod}
                />
              )}
            />

            <EmailValidationroute path={`/api/v1`} component={DashboardScene} />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/my-agents`}
              component={AgentsScene}
            />

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/commissions`}
              component={CommisionsSceen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/inventory`}
              component={InventorySceen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/pos-request`}
              component={MyPosRequestScreen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/pos-request/approve-pos`}
              component={ApprovePosScreen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/pos-request/track-shipment`}
              component={TrackShipment}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/pos-management/aggregator/request-pos`}
              component={RequestPosAggregatorScreen}
            />

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/commissions/unload-commission`}
              component={UnloadBalanceSceen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/commissions/withdrawal-details/:id`}
              component={WithdrawalDetailsSceen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/my-services`}
              component={ServicesScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/setup-new-agent`}
              component={
                process.env.REACT_APP_SHOW_AGGREGATOR_MODIFICATION === "true"
                  ? SetupAgentRevamp
                  : SetupAgent
              }
            />

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/applications`}
              component={ApplicationsScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/applications/:id`}
              component={ApplicationsDetailsScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/my-agents/upgrade/:id`}
              component={ListOfclassScreen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/my-agents/upgrade/upgrade-class/:id`}
              component={SetupAgentClass}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/my-agents/manage/:id`}
              component={manageAgentScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/agents/performance`}
              component={PerformanceSceen}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/my-reports`}
              component={ReportsScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/view-issues`}
              component={IssueReportScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/complaint`}
              component={ComplaintForm}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/ticket`}
              component={TicketDetails}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/reopen-ticket`}
              component={ReopenTicket}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-complaint`}
              component={ManageComplaint}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/historical-transactions`}
              component={HistoricalTransactionsScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/wallet-journal`}
              component={WalletJournalScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/notifications`}
              component={NotificationScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/bank-network`}
              component={BankNetwork}
            />

            {process.env.REACT_APP_SHOW_NIN_VERIFICATION ? (
              <Route
                exact
                path={`${process.env.REACT_APP_ROUTE_BASENAME}/nin-information`}
                render={() => (
                  <NinInformation disableNavBar={this.setEnableNavBar} />
                )}
              />
            ) : (
              ""
            )}

            {process.env.REACT_APP_SHOW_NIN_VERIFICATION ? (
              <Route
                exact
                path={`${process.env.REACT_APP_ROUTE_BASENAME}/account-downgrade`}
                render={() => (
                  <AccountDowngradeScene disableNavBar={this.setEnableNavBar} />
                )}
              />
            ) : (
              ""
            )}

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices`}
              component={ManageDevicesScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management`}
              component={PosManagement}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/device-audit-trail`}
              component={ManageDevicesAuditTrailScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/my-device`}
              component={ManageDevicesScene}
            />

            {/* <Route path={`${process.env.REACT_APP_ROUTE_BASENAME}/dashboard`} component={() => <DashboardScene currentApplication={this.state.currentApplication} currentAgent={this.state.currentAgent} disabled={this.state.disabled} loadUser={this.loadUser} getWallet={this.getWallet} />} /> */}
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/request-pos`}
              component={RequestPos}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-report`}
              component={PosReport}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/remap-pos`}
              component={RemapScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-remap-confirmation`}
              component={DelieveryNote}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/pos-receipt-confirmation`}
              component={ConcurDelivery}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/track-pos-order`}
              component={TrackPosOrder}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-devices/pos-management/track-pos-delivery`}
              component={TrackPosDelivery}
            />

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile`}
              component={SetupAgentDisplay}
            />
            {process.env.REACT_APP_NAV_MENU_SHOW_MANAGE_PROFILE ? (
              <Route
                exact
                path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-AgentProfile`}
                component={ManageProfileSceneNew}
              />
            ) : (
              ""
            )}

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-list`}
              component={ListOfUpgradeType}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-list/:id`}
              component={SetupNewAgentClass}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-status`}
              component={SetupAgentResume}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/manage-profile/upgrade-list/prestige/:id`}
              component={SetupNewAgentClassPrestige}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/security-settings`}
              component={SecuritySettingsScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/link-debit-card`}
              component={LinkDebitCardScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/complete-debit-card-link`}
              component={CompleteDebitCardLinkScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/account-opening`}
              component={AccountOpeningScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/account-opening-documents`}
              component={AccountOpeningDocumentsScene}
            />

            <Route
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/roles`}
              component={RolesScene}
            />
            <Route
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/users`}
              component={UsersScene}
            />

            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/services/:slug`}
              component={ServicesScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/my-services/:slug`}
              component={ServicesScene}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_ROUTE_BASENAME}/:slug`}
              component={ServicesScene}
            />
            <Route exact path={`/:slug`} component={ServicesScene} />

            {/* <Route exact path={`${process.env.REACT_APP_ROUTE_BASENAME}/notifications`} component={NotificationsScene}  /> */}
          </Switch>
        )
      ) : (
        <Skeleton
          didErrorOccurWhileFetching={didErrorOccurWhileFetching}
          isLoading={this.props.isLoading}
          loadData={() => {
            const doHardLoadUser = false;
            const doHardLoadAgent = false;
            this.loadData(doHardLoadUser, doHardLoadAgent);
          }}
        />
      );

    // </Switch>

    let modifiedAgentDetails = JSON.parse(
      localStorage.getItem("modifiedAgentDetails")
    );

    const mobileNavBar = (
      <div
        className="nav-bar"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          height: "100vh",
          opacity: 1,
          position: "fixed",
          right: this.state.mobileNavBarRight,
          width: "100%",
          zIndex: "100",
        }}
        hidden={!this.state.showMobileNavBar}
      >
        <NavBar
          disabled={this.state.disabled}
          style={{ width: "75%" }}
          onClick={this.hideNavBar}
        />
        <div
          id="side-bar-stuff"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            opacity: this.state.mobileNavBarOpacity,
            width: "25%",
            right: "0px",
            position: "absolute",
            height: "100vh",
          }}
          onClick={this.hideNavBar}
        />
      </div>
    );

    const mobileContainer = (
      <Row
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <Header
          disabled={this.state.disabled}
          hasNavBar
          showNavBar={this.showNavBar}
          outerDivStyle={{
            width: "100%",
            backgroundColor: "white",
          }}
        />
        {mobileNavBar}
        <section
          style={{
            marginTop: "70px",
            width: "100vw",
          }}
        >
          {switchComponent}
        </section>
      </Row>
    );

    const desktopNavBar = (
      <NavBar
        disabled={this.state.disabled}
        onClick={() => {}}
        style={{ width: "17vw", zIndex: 100 }}
      />
    );
    const desktopContainer = (
      <div
        style={{ display: "flex", flexDirection: "row", margin: 0, padding: 0 }}
      >
        {desktopNavBar}
        <section
          style={{
            marginLeft: "17vw",
          }}
        >
          {switchComponent}
        </section>
      </div>
    );

    if (this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }

    let application = JSON.parse(localStorage.getItem("currentAgent"));

    const { showNewCbnModal, showUpdateAccountModal } = this.state;
    const { showCompleteOnboarding } = this.state;

    const navigateToPath = () => {
      if (application?.kycCheckList?.livelinessCheck === true) {
        if (
          application?.kycCheckList?.bvnNinMatch === false &&
          application?.kycCheckList?.tinNinMatch === false
        ) {
          this.props.navigateTo("/nin-verification");
        } else if (
          application?.kycCheckList?.bvnNinMatch === true &&
          application?.kycCheckList?.tinNinMatch === false
        ) {
          this.props.navigateTo("/business-cbn");
        } else {
          this.props.navigateTo("/bvn-validation");
        }
      } else {
        this.props.navigateTo("/bvn-validation");
      }
    };

    const navigateToPath2 = () => {
      if (application) {
        this.props.navigateTo("/kyc-continuation-flow/setupagent");
      }
    };

    return (
      <>
        <Container
          fluid
          style={{
            minHeight: "100vh",
            height: "100%",
            overflow: "hidden",
            position: "relative",
            paddingBottom: "100px",
            padding: 0,
            margin: 0,
            backgroundColor: "#EEF2F4",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
          }}
        >
          <BrowserRouter>
            {!this.state.deviceIsPC ? mobileContainer : desktopContainer}
            {/* <IdleTimer 
          onIdle={this.onIdle}
          timeout={process.env.REACT_APP_IDLE_TIMEOUT}
        /> */}
          </BrowserRouter>

          {!this.props.isLoading &&
            this.state.currentAgent &&
            this.state.currentAgent.isActive &&
            this.state.deviceIsPC && <TourGuide />}
        </Container>

        {process.env.REACT_APP_CBN_ONBOARDING === "true" &&
        showNewCbnModal &&
        application?.agentTypeId === 4 &&
        application?.kycStatus !== "COMPLETED" ? (
          <Modal
            show={this.state.showNewCbnModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
              <div
                style={{
                  padding: "3rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    fontSize: "1.5rem",
                  }}
                >
                  Complete your KYC
                </h1>
                <p
                  style={{
                    color: "grey",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Please provide your complete KYC documentation so you can
                  continue transacting.
                </p>
                <button
                  className="signupButton3"
                  style={{
                    marginTop: "3rem",
                    textAlign: "center",
                    backgroundColor: "#00425F",
                    color: "#FFFFFF",
                  }}
                  onClick={navigateToPath}
                >
                  Provide your KYC
                </button>

                {process.env.REACT_APP_DISABLE_CBN_SKIP === "true" ? (
                  <button
                    className="signupButton4"
                    style={{
                      marginTop: "1rem",
                      textAlign: "center",
                      color: "black",
                      alignContent: "center",
                    }}
                    onClick={() => {
                      logoutUser();
                      this.props.navigateTo("/login");
                    }}
                  >
                    Don't have your KYC details?{" "}
                    <span style={{ color: "#0275D8" }}>Logout.</span>
                  </button>
                ) : (
                  <button
                    className="signupButton3"
                    style={{
                      marginTop: "1rem",
                      textAlign: "center",
                      color: "black",
                      alignContent: "center",
                    }}
                    onClick={this.handleCloseModal}
                  >
                    Skip
                  </button>
                )}
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}

        {process.env.REACT_APP_SHOW_RESUME_KYC_ONBOARDING === "true" &&
        application?.agentClassId === 0 &&
        showCompleteOnboarding ? (
          <Modal
            show={this.state.showCompleteOnboarding}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
              <div
                style={{
                  padding: "3rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    fontSize: "1.5rem",
                  }}
                >
                  Start Transacting
                </h1>
                <p
                  style={{
                    color: "grey",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Provide your next of kin and referee details to start
                  transacting.
                </p>
                <button
                  className="signupButton3"
                  style={{
                    marginTop: "3rem",
                    textAlign: "center",
                    backgroundColor: "#00425F",
                    color: "#FFFFFF",
                  }}
                  onClick={navigateToPath2}
                >
                  Proceed
                </button>

                <button
                  className="signupButton3"
                  style={{
                    marginTop: "1rem",
                    textAlign: "center",
                    color: "black",
                    alignContent: "center",
                  }}
                  onClick={this.handleCloseModal2}
                >
                  Not now, Later
                </button>
                {/* )} */}
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}

        {(process.env.REACT_APP_UPGRADE_STANDARD === "true" &&
          modifiedAgentDetails?.count === 0) ||
        modifiedAgentDetails?.content[0]?.status === 0 ||
        modifiedAgentDetails?.content[0]?.status === 1 ? (
          <Modal
            show={this.state.showModal}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
              <div style={{ padding: "3rem", alignContent: "center" }}>
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    fontSize: "1.5rem",
                  }}
                >
                  Upgrade your Account
                </h1>
                <p
                  style={{
                    color: "grey",
                    fontWeight: "400",
                    marginLeft: "1.5rem",
                  }}
                >
                  Unlock more services and features when you <br />
                  <span style={{ marginLeft: "2rem" }}>
                    upgrade your account completely
                  </span>
                </p>
                <button
                  className="signupButton3"
                  style={{
                    marginTop: "3rem",
                    textAlign: "center",
                    backgroundColor: "#00425F",
                    color: "#FFFFFF",
                    alignContent: "center",
                    marginLeft: "1.5rem",
                  }}
                  onClick={this.showUpdateModal}
                >
                  Upgrade{" "}
                </button>
                <button
                  className="signupButton3"
                  style={{
                    marginTop: "1rem",
                    textAlign: "center",
                    color: "black",
                    alignContent: "center",
                    marginLeft: "1.5rem",
                  }}
                  onClick={this.hideUpdateModal}
                >
                  Skip
                </button>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}

        {process.env.REACT_APP_SHOW_NIN_VERIFICATION &&
          (application?.agentClass === "PRESTIGE" ||
            application?.agentClass === "STANDARD" ||
            application?.agentClass === "CLASSIC") &&
          // new Date() > new Date("2024-03-1") &&
          application?.ninVerificationStatus !== "VERIFIED" &&
          showUpdateAccountModal && (
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body style={{ padding: "1rem", alignSelf: "center" }}>
                <div
                  style={{
                    padding: "3rem",
                    alignContent: "center",
                    position: "relative",
                  }}
                >
                  <>
                    {/* <svg
                      onClick={this.closeModal}
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        cursor: "pointer",
                      }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.34315 6.34315C6.53857 6.14773 6.83476 6.14773 7.03018 6.34315L12 11.3137L16.9695 6.34315C17.1649 6.14773 17.4611 6.14773 17.6565 6.34315C17.8519 6.53857 17.8519 6.83476 17.6565 7.03018L12.6869 12L17.6565 16.9695C17.8519 17.1649 17.8519 17.4611 17.6565 17.6565C17.4611 17.8519 17.1649 17.8519 16.9695 17.6565L12 12.6869L7.03018 17.6565C6.83476 17.8519 6.53857 17.8519 6.34315 17.6565C6.14773 17.4611 6.14773 17.1649 6.34315 16.9695L11.3137 12L6.34315 7.03018C6.14773 6.83476 6.14773 6.53857 6.34315 6.34315Z"
                        fill="black"
                      />
                    </svg> */}
                    <h1
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        fontSize: "1.5rem",
                      }}
                    >
                      Account Update
                    </h1>
                    <p
                      style={{
                        color: "grey",
                        fontWeight: "400",
                        marginLeft: "1.5rem",
                      }}
                    >
                      Your account has been restricted according to CBN
                      requirement. Kindly update your info to continue at your
                      current account class or downgrade to access lower limits.
                    </p>
                    <button
                      className="signupButton3"
                      style={{
                        marginTop: "3rem",
                        textAlign: "center",
                        backgroundColor: "#00425F",
                        color: "#FFFFFF",
                        alignContent: "center",
                        marginLeft: "1.5rem",
                      }}
                      onClick={() => {
                        this.props.navigateTo("/nin-information");
                        this.closeModal();
                      }}
                    >
                      Update my account
                    </button>
                    <button
                      className="signupButton3"
                      style={{
                        marginTop: "1rem",
                        textAlign: "center",
                        color: "black",
                        alignContent: "center",
                        marginLeft: "1.5rem",
                      }}
                      onClick={() => {
                        this.props.navigateTo("/account-downgrade");
                        this.closeModal();
                      }}
                    >
                      Downgrade account
                    </button>
                  </>
                </div>
              </Modal.Body>
            </Modal>
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
    loadUserStatus: state.user.loadUserStatus,
    activeTab: state.navigation.activeTab,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    endLoadUserData: () => dispatch(endLoadUserData()),
    setLoadingUserData: () => dispatch(setLoadingUserData()),
    dismissErrorMessage: (message) => dispatch(dismissErrorMessage(message)),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    setActiveTab: (tab) => dispatch(setActiveTab(tab)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScene);
